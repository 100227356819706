import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Link, Svg, Button } from './ui';
import { dataLayer, slugify } from '../utils';
import Logo from './Logo';
import instagramIcon from '../images/instagram-icon.svg';
import facebookIcon from '../images/facebook-icon.svg';
import linkedinIcon from '../images/linkedin-icon.svg';
import googlePayLogo from '../images/google-pay-logo.svg';
import paypalLogo from '../images/paypal-logo.svg';
import visaLogo from '../images/visa-logo.svg';
import mastercardLogo from '../images/mastercard-logo.svg';
import maestroLogo from '../images/maestro-logo.svg';
import amexLogo from '../images/amex-logo.svg';

const StyledFooter = styled.footer`
  padding: 10px 0;
  background-color: ${brandColours.tertiary};
`;

const StyledTop = styled.div`
  ${sectionMargins(undefined, '70px')};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 20px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr repeat(4, 2fr) 3fr;
  `}
`;

const StyledLogo = styled(Logo)`
  fill: ${standardColours.white};

  ${maxBreakpointQuery.small`
    grid-column: span 2;
  `}
`;

const StyledGroup = styled.div`
  ${({ newsletter }) => {
    if (newsletter) {
      return css`
        ${maxBreakpointQuery.smedium`
          grid-column: span 3;
        `}

        ${maxBreakpointQuery.small`
          grid-column: span 2;
        `}
      `;
    }
  }}
`;

const StyledHeading = styled.h3`
  margin-bottom: 16px;
  color: ${standardColours.white};
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
    ${fontSize(16)};
  `}
`;

const StyledList = styled.ul``;

const StyledListItem = styled.li`
  margin: 6px 0;
`;

const StyledListLink = styled(Link)`
  padding: 6px 0;
  color: ${standardColours.white};
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledAddress = styled.address`
  padding: 6px 0;
  white-space: break-spaces;
  color: ${standardColours.white};
  font-style: normal;
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledDirectionLink = styled.a`
  display: block;
  padding: 6px 0;
  text-decoration: underline;
  text-underline-offset: 2px;
  color: ${standardColours.white};
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledSocialLink = styled.a`
  display: inline-block;
  margin: 10px 20px 10px 0;

  ${minBreakpointQuery.large`
    margin-right: 26px;
  `}

  &:last-child {
    margin-right: 0;
  }
`;

const StyledSocialIcon = styled(Svg)`
  height: 18px;
  width: 18px;
  fill: ${standardColours.white};
`;

const StyledForm = styled.form``;

const StyledLabel = styled.label`
  display: block;
  margin: 10px 0;

  span {
    ${visuallyHidden()};
  }
`;

const StyledInput = styled.input`
  padding: 20px;
  height: 64px;
  width: 100%;
  color: ${standardColours.white};
  background: none;
  border: 1px solid ${standardColours.white};

  &::placeholder {
    color: ${standardColours.white};
  }
`;

const StyledButton = styled(Button)`
  justify-content: left;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
`;

const StyledBottom = styled.div`
  ${sectionMargins(undefined, '70px')};

  ${minBreakpointQuery.smedium`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledPaymentLogos = styled.div`
  ${maxBreakpointQuery.smedium`
    margin-bottom: 20px;
  `}
`;

const StyledPaymentLogo = styled(Svg)`
  margin: 5px 20px 5px 0;
  height: 30px;
  width: 42px;
  fill: ${standardColours.white};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledText = styled.p`
  color: ${standardColours.white};
  ${fontSize(13)};
  line-height: 1.5;

  ${minBreakpointQuery.large`
    ${fontSize(14)};
  `}
`;

const Footer = () => {
  const {
    datoCmsFooter: { aboutLinks, companyLinks },
    datoCmsContactInformation: {
      shopLocations,
      instagramProfile,
      facebookProfile,
      linkedinProfile,
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsFooter {
        aboutLinks {
          id
          text
          page {
            ...LinkFragment
          }
        }
        companyLinks {
          id
          text
          page {
            ...LinkFragment
          }
        }
      }
      datoCmsContactInformation {
        shopLocations {
          address
        }
        instagramProfile
        facebookProfile
        linkedinProfile
      }
    }
  `);

  const navLinks = [
    {
      heading: 'About',
      links: aboutLinks,
    },
    {
      heading: 'Company',
      links: companyLinks,
    },
  ];

  const socialLinks = [
    {
      text: 'Instagram',
      icon: instagramIcon,
      url: instagramProfile,
    },
    {
      text: 'Facebook',
      icon: facebookIcon,
      url: facebookProfile,
    },
    {
      text: 'LinkedIn',
      icon: linkedinIcon,
      url: linkedinProfile,
    },
  ];

  const paymentLogos = [
    {
      name: 'Google Pay',
      logo: googlePayLogo,
    },
    {
      name: 'PayPal',
      logo: paypalLogo,
    },
    {
      name: 'Visa',
      logo: visaLogo,
    },
    {
      name: 'Mastercard',
      logo: mastercardLogo,
    },
    {
      name: 'Maestro',
      logo: maestroLogo,
    },
    {
      name: 'Amex',
      logo: amexLogo,
    },
  ];

  const [formSubmitted, setFormSubmitted] = useState(false);

  const emailInput = useRef();

  const handleSubmit = event => {
    event.preventDefault();
    fetch('/api/subscribe', {
      method: 'POST',
      body: JSON.stringify({
        email: emailInput.current.value,
      }),
    })
      .then(() => {
        setFormSubmitted(true);
        dataLayer('newsletterFormSubmit');
      })
      .catch(error => alert(error));
  };

  return (
    <StyledFooter>
      <Container>
        <StyledTop>
          <StyledLogo />
          {navLinks.map(({ heading, links }, i) => (
            <StyledGroup key={`footer-links-${i}`}>
              <StyledHeading>{heading}</StyledHeading>
              <StyledList>
                {links.map(({ id, text, page }) => (
                  <StyledListItem key={id}>
                    <StyledListLink to={page.slug} linkData={page}>
                      {text}
                    </StyledListLink>
                  </StyledListItem>
                ))}
              </StyledList>
            </StyledGroup>
          ))}
          <StyledGroup>
            <StyledHeading>Find Us</StyledHeading>
            <StyledList>
              {shopLocations.map(({ address }, id) => (
                <StyledListItem key={id}>
                  <StyledAddress>{address}</StyledAddress>
                  <StyledDirectionLink
                    href={`https://www.google.co.uk/maps/dir//${slugify(
                      address
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (Get Directions)
                  </StyledDirectionLink>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledGroup>
          <StyledGroup>
            <StyledHeading>Social</StyledHeading>
            {socialLinks.map(({ text, icon, url }, i) => (
              <StyledSocialLink
                key={`footer-links-${i}`}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledSocialIcon title={text} image={icon} />
              </StyledSocialLink>
            ))}
          </StyledGroup>
          <StyledGroup newsletter={true}>
            <StyledHeading>Sign up to our newsletter</StyledHeading>
            <StyledForm method="post" onSubmit={handleSubmit}>
              <StyledLabel for="newsletter-email">
                <span>Your email address</span>
                <StyledInput
                  type="email"
                  id="newsletter-email"
                  name="newsletter-email"
                  ref={emailInput}
                  placeholder="Your email address"
                  required
                />
              </StyledLabel>
              <StyledButton removeArrow={true} disabled={formSubmitted}>
                {formSubmitted ? 'Thanks' : 'Subscribe'}
              </StyledButton>
            </StyledForm>
          </StyledGroup>
        </StyledTop>
        <StyledBottom>
          <StyledPaymentLogos>
            {paymentLogos.map(({ name, logo }) => (
              <StyledPaymentLogo title={name} image={logo} />
            ))}
          </StyledPaymentLogos>
          <StyledText>
            &copy; {new Date().getFullYear()} Root Houseplants (Company number
            12122447) | Website by{' '}
            <a
              href="https://www.attachdigital.co.uk"
              target="_blank"
              rel="noopener"
            >
              Attach Digital
            </a>
          </StyledText>
        </StyledBottom>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
