import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../../styles';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 40px;
  margin: 20px 0;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, calc(50% - 20px));
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, calc(50% - 30px));
    grid-gap: 60px;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(2, calc(50% - 40px));
    grid-gap: 80px;
  `}
  
  ${minBreakpointQuery.xlarge`
    grid-template-columns: repeat(2, calc(50% - 50px));
    grid-gap: 100px;
  `}
`;

export const Grid = ({ children, ...props }) => (
  <StyledGrid {...props}>{children}</StyledGrid>
);
