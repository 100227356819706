import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from '../styles/GlobalStyle';
import Header from './Header';
import FeaturedLogos from './FeaturedLogos';
import Footer from './Footer';
import Cart from './Cart';
import { StoreContext } from '../context/StoreContext';

const Layout = ({ children, isHomepage }) => {
  const {
    datoCmsSite: { faviconMetaTags },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);

  const { isOverlayActive } = useContext(StoreContext);

  return (
    <>
      <HelmetDatoCms favicon={faviconMetaTags}>
        <html lang="en-GB" />
        <meta
          name="google-site-verification"
          content="sQrqqXrVOlXzXtR-j0Zg0awZwlGSwTUQxdd8sThVhSI"
        />
        <script
          crossorigin
          defer
          type="module"
          data-qbud
          data-host="api.qbud.ai"
          data-color="rgb(5, 130, 103)"
          data-assistant-id="528bfdad-c20b-4cb1-9eb4-b8d7889ab556"
          src="https://app.qbud.ai/cdn/qbud.js"
        ></script>
        <link
          rel="stylesheet"
          crossorigin
          href="https://app.qbud.ai/cdn/qbud.css"
        ></link>
      </HelmetDatoCms>
      <GlobalStyle isOverlayActive={isOverlayActive} />
      <Header />
      {children}
      {!isHomepage && <FeaturedLogos />}
      <Footer />
      <Cart />
    </>
  );
};

export default Layout;
